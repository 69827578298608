<script setup lang="ts"></script>

<template>
    <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_1525_13584)">
            <path
                d="M1.21875 5.21875V26.0863H8.405V30H12.3288L16.2463 26.085H22.1275L29.97 18.2625V0H3.1775L1.21875 5.21875ZM5.79125 2.60625H27.3563V16.955L22.7813 21.52H15.5925L11.675 25.4288V21.52H5.79125V2.60625Z"
                fill="url(#paint0_linear_1525_13584)"
            />
            <path
                d="M12.9812 7.8275H15.5938V15.6525H12.9812V7.8275Z"
                fill="url(#paint1_linear_1525_13584)"
            />
            <path
                d="M20.1662 7.8275H22.78V15.6525H20.1662V7.8275Z"
                fill="url(#paint2_linear_1525_13584)"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_1525_13584"
                x1="15.5944"
                y1="0"
                x2="15.5944"
                y2="30"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#A2BADC" />
                <stop offset="1" stop-color="#68799C" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_1525_13584"
                x1="15.5944"
                y1="0"
                x2="15.5944"
                y2="30"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#A2BADC" />
                <stop offset="1" stop-color="#68799C" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_1525_13584"
                x1="15.5944"
                y1="0"
                x2="15.5944"
                y2="30"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#A2BADC" />
                <stop offset="1" stop-color="#68799C" />
            </linearGradient>
            <clipPath id="clip0_1525_13584">
                <rect width="30" height="30" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
